:root {
    --light-yellow: #f6da6d;
    --dark-yellow: #ba8c2d;
    --dark-black: #151515;
    --light-black: #323232;
}


.login-container {
    background: rgb(2, 69, 157);
    background: radial-gradient(circle, rgba(2, 69, 157, 1) 0%, rgba(19, 33, 60, 1) 50%);
}

.gradientText {
    background: var(--light-yellow);
    background: linear-gradient(90deg, var(--light-yellow) 0%, var(--dark-yellow) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.loginBtn {
    background: rgb(75, 75, 75) !important;
    background: linear-gradient(90deg, rgba(75, 75, 75, 1) 0%, rgba(19, 19, 19, 1) 100%) !important;
}

.inputBoxBg {
    background: rgb(75, 75, 75) !important;
    background: linear-gradient(90deg, rgba(75, 75, 75, 1) 0%, rgba(19, 19, 19, 1) 100%) !important;
}

.inputBoxBorder {
    border: 2px solid var(--dark-yellow);
}

.inputBoxBorder:focus {
    outline: none;
    border-color: var(--dark-yellow);
}

.bgGradient {
    background: rgb(75, 75, 75);
    background: linear-gradient(90deg, rgba(75, 75, 75, 1) 25%, rgba(19, 19, 19, 1) 100%);
}

.sidebar-bg {
    background: rgb(38, 38, 38);
    background: linear-gradient(180deg, rgba(38, 38, 38, 1) 0%, rgba(73, 65, 49, 1) 100%);
}

.sidebar-container {
    overflow-y: auto;
}

.btnGradient {
    /* background: #f6da6d;
    background: linear-gradient(0deg, #f6da6d 32%, #ba8c2d  100%); */
    background: rgb(186, 140, 45) !important;
    background: linear-gradient(0deg, rgba(186, 140, 45, 1) 0%, rgba(246, 218, 109, 1) 150%) !important;
}

.headingBG {
    background: rgb(186, 140, 45) !important;
    background: linear-gradient(0deg, rgba(186, 140, 45, 1) 0%, rgba(246, 218, 109, 1) 150%) !important;
}

.borderStyle {
    border-color: var(--dark-yellow);
}

.darkYellowText {
    color: var(--dark-yellow);
}

.lightYellowText {
    color: var(--light-yellow);
}

.checkBox {
    width: 1rem;
    height: 1rem;
    accent-color: var(--light-yellow);
}


.errorMsg {
    height: 14px;

    p {
        font-size: 12px !important;
        color: red !important;
    }
}

table {
    background-color: var(--dark-black) !important;
}

th {
    background: rgb(186, 140, 45) !important;
    background: linear-gradient(0deg, rgba(186, 140, 45, 1) 0%, rgba(246, 218, 109, 1) 150%) !important;
    color: white !important;
    white-space: nowrap !important;
    text-align: center !important;
}

td {
    background-color: var(--dark-black) !important;
    color: white !important;
    border-bottom: 1px dotted var(--dark-yellow) !important;
    text-align: center !important;
    background-position: center !important;
}

.ant-table-cell-row-hover {
    background-color: rgba(192, 192, 192, 0.065) !important;
}

.darkBg {
    background-color: var(--dark-black);
}

.fieldBg {
    background-color: var(--light-black);
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--dark-yellow);
    border-radius: 50px;
}

::-webkit-scrollbar-track {
    background-color: var(--dark-black);
}

.ant-modal-content {
    background: radial-gradient(circle, rgba(73, 65, 49, 1) 0%, rgba(38, 38, 38, 1) 100%) !important;
    position: relative !important;
    overflow: hidden !important;
    z-index: -1;
    border: 1px dotted var(--dark-yellow) !important;
}

.ant-modal-header {
    background-color: transparent !important;
}

.ant-modal-header * {
    color: white !important;

}

.ant-modal-content::after {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    content: "" !important;

    z-index: -1 !important;
}

.ant-modal-content * {
    color: white !important;
    z-index: 999999 !important;
    font-size: 14px !important;
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.10) !important;
    backdrop-filter: blur(4px) !important;
    -webkit-backdrop-filter: blur(4px) !important;
}

.logoutIconOuter {
    /* background-color: rgba(255, 255, 255, 0.245); */

    padding: 30px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.logoutText {
    font-size: 20px;
}

.ant-empty-description {
    color: white !important;
}

.ant-image-mask-info {
    overflow: visible !important;
    white-space: nowrap !important;
}

.ant-radio-button-wrapper {
    color: white !important;
    background: transparent !important;
    border: 1px solid var(--dark-yellow) !important;
}

.ant-radio-button-wrapper:hover {
    color: var(--light-yellow) !important;
}

.ant-radio-button-wrapper-checked {
    color: white !important;
    /* background-color: #ba8c2d !important; */
    border-color: white !important;
    background: rgb(186, 140, 45) !important;
    background: linear-gradient(0deg, rgba(186, 140, 45, 1) 0%, rgba(246, 218, 109, 1) 150%) !important;
}

:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent !important;
}


.ant-table-column-sorter-up.active {
    color: black !important;
}

.ant-table-column-sorter-down.active {
    color: black !important;
}

/* HTML: <div class="loader"></div> */
.loaderDiv {
    z-index: 99999;
    backdrop-filter: blur(6px);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.loader {
    width: 100px;
    height: 40px;
    --g: radial-gradient(farthest-side, #0000 calc(95% - 3px), var(--dark-yellow) calc(100% - 3px) 98%, #0000 101%) no-repeat;
    background: var(--g), var(--g), var(--g);
    background-size: 30px 30px;
    animation: l9 1s infinite alternate;
}

.d-none {
    display: none;
}

@keyframes l9 {
    0% {
        background-position: 0 50%, 50% 50%, 100% 50%;
    }

    20% {
        background-position: 0 0, 50% 50%, 100% 50%;
    }

    40% {
        background-position: 0 100%, 50% 0, 100% 50%;
    }

    60% {
        background-position: 0 50%, 50% 100%, 100% 0;
    }

    80% {
        background-position: 0 50%, 50% 50%, 100% 100%;
    }

    100% {
        background-position: 0 50%, 50% 50%, 100% 50%;
    }
}


.ant-pagination-item-active {
    font-weight: 600;
    /* background-color: #ba8c2d !important; */
    border-color: var(--dark-yellow) !important;
    background: rgb(186, 140, 45) !important;
    background: linear-gradient(0deg, rgba(186, 140, 45, 1) 0%, rgba(246, 218, 109, 1) 150%) !important;
}

.ant-pagination a {
    color: white !important;
}

.anticon svg {
    color: white !important;
}

.ant-picker-outlined {
    /* background-color: #f6da6d !important; */
    background: rgb(186, 140, 45) !important;
    background: linear-gradient(90deg, rgba(186, 140, 45, 1) 0%, rgba(246, 218, 109, 1) 150%) !important;
    border-color: var(--dark-yellow) !important;
    color: var(--dark-yellow) !important;
}

.ant-picker-header-view {
    color: var(--dark-yellow) !important;

}

.ant-picker-header-super-next-btn {
    color: var(--dark-yellow) !important;

}

.ant-picker-header-super-next-btn:hover {
    color: white !important;
}

.ant-picker-header-next-btn {
    color: var(--dark-yellow) !important;
}

.ant-picker-header-next-btn:hover {
    color: white !important;
}

.ant-picker-header-super-prev-btn {
    color: var(--dark-yellow) !important;
}

.ant-picker-header-super-prev-btn:hover {
    color: white !important;
}

.ant-picker-header-prev-btn {
    color: var(--dark-yellow) !important;
}

.ant-picker-header-prev-btn:hover {
    color: white !important;
}

.ant-picker-now-btn {
    color: var(--dark-yellow) !important;
}

.ant-picker-now-btn:hover {
    color: white !important;
}

.ant-picker-footer {
    background-color: rgba(0, 0, 0, 0.829) !important;
}

.ant-picker-header {
    background-color: rgba(0, 0, 0, 0.829) !important;
}

.ant-picker-body {
    background-color: rgba(0, 0, 0, 0.829) !important;
}

.ant-picker-cell-inner-selected {
    background-color: var(--dark-yellow) !important;
}

.ant-picker-cell-inner::before {
    border: 1px solid var(--dark-yellow) !important;
}

.ant-picker-cell-in-view
.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end {
    background: var(--dark-yellow) !important;
}
.ant-picker-cell-disabled{
    color: gray !important;
}

.ant-picker-year-btn:hover {
    color: white !important;
}

.ant-picker-month-btn:hover {
    color: white !important;
}

.ant-picker-decade-btn:hover {
    color: white !important;
}

.ant-picker-input-placeholder input {
    color: white !important;
}

.ant-picker-input {
    color: white !important;
}

::placeholder {
    color: #f5f5f5d5 !important;
    font-size: 14px !important
}

.ant-select-selector {
    border: 2px solid var(--dark-yellow) !important;
    background: rgb(75, 75, 75) !important;
    background: linear-gradient(90deg, rgba(75, 75, 75, 1) 0%, rgba(19, 19, 19, 1) 100%) !important;
}

.ant-select-selection-item {
    color: white !important;
}

.apexcharts-tooltip * {
    color: #000 !important;
}

.ant-select-item-option-active {
    background-color: #f6db6d42 !important;
}

.ant-select-item-option-selected {
    background-color: var(--dark-yellow) !important;
}

.ant-select-dropdown {
    background-color: var(--light-black) !important;
}

.ant-select-item-option-content {
    color: white !important;
}

.ant-switch {
    background-color: #f55b5b !important;
}

.ant-switch-checked {
    background: #05b3f8 !important;
}

.ant-switch-handle::before {
    background-color: white !important;
}

.transition-content {
    overflow: hidden;
    transition: height 0.6s ease, opacity 0.3s ease;
    opacity: 0;
    height: 0;
}

.transition-content.open {
    opacity: 1;
    height: auto;
}

.ant-upload-wrapper {
    display: block;
}

@keyframes break {
    0% {
        transform: translateX(0) translateY(0) rotate(30deg);
    }

    50% {
        transform: translateX(calc(var(--i) * 1px)) translateY(calc(var(--i) * 10px)) rotate(calc(var(--i) * 5deg));
    }

    100% {
        transform: translateX(100) translateY(0) rotate(30deg);
    }
}

.break-animation {
    display: inline-block;
    animation: break 2s ;
    position: relative;
}

.break-animation::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    animation: break 2s ;
}

.break-animation span {
    display: inline-block;
    position: relative;
    animation: break 2s ;
}

.break-animation span:nth-child(odd) {
    --i: -5;
}

.break-animation span:nth-child(even) {
    --i: 20;
}
